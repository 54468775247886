import React, { Component } from 'react';
import './styles.css';
import { ref, onValue, set } from 'firebase/database';
import StartFirebase from '../../configFirebase/index';
import { Autocomplete, Box, Button, Grid, IconButton, LinearProgress, MenuItem, Modal, TextField } from '@mui/material';
import moment from 'moment';
import { CheckCircle, Close } from '@mui/icons-material';

const db = StartFirebase();

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid grey',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

class RegistrasiPasien extends Component {
  state = {
    database: [],
    dataTable: [],
    dataPasien: {},
    showFields: false,
    loading: false,
    modalSubmit: false,
    dokterPoli: ''
  }

  componentDidMount() {
    const dbRef = ref(db, 'pasien');
    this.setState({ database: StartFirebase() });
    onValue(dbRef, (snapshot) => {
      let records = [];
      snapshot.forEach(childSnapshot => {
        let data = childSnapshot.val();
        records.push(data);
      });
      this.setState({ dataTable: records })
    });
  }

  getAllData = () => {
    return {
      alamat: this.state.dataPasien.alamat,
      asuransi: this.state.dataPasien.asuransi,
      jamBerkunjung: this.state.dataPasien.jamBerkunjung,
      jenisKelamin: this.state.dataPasien.jenisKelamin,
      namaLengkap: this.state.dataPasien.namaLengkap,
      nik: this.state.dataPasien.nik,
      noAsuransi: this.state.dataPasien.noAsuransi,
      noRM: this.state.dataPasien.noRM,
      noRegistrasi: this.state.dataPasien.noRegistrasi,
      noRujukan: this.state.dataPasien.noRujukan,
      noTelp: this.state.dataPasien.noTelp,
      poli: this.state.dataPasien.poli,
      tempatLahir: this.state.dataPasien.tempatLahir,
      tglBerkunjung: this.state.dataPasien.tglBerkunjung,
      tglLahir: this.state.dataPasien.tglLahir,
      queueNumber: this.state.dataPasien.queueNumber,
      dokterPoli: this.state.dokterPoli,
      registrasiUlang: true
    };
  }

  _handleRegist = (e) => {
    e.preventDefault();
    let db = this.state.database;
    let data = this.getAllData();

    set(ref(db, 'pasien/' + data.queueNumber), {
      alamat: data.alamat,
      asuransi: data.asuransi,
      jamBerkunjung: data.jamBerkunjung,
      jenisKelamin: data.jenisKelamin,
      namaLengkap: data.namaLengkap,
      nik: data.nik,
      noAsuransi: data.noAsuransi,
      noRM: data.noRM,
      noRegistrasi: data.noRegistrasi,
      noRujukan: data.noRujukan,
      noTelp: data.noTelp,
      poli: data.poli,
      tempatLahir: data.tempatLahir,
      tglBerkunjung: data.tglBerkunjung,
      tglLahir: data.tglLahir,
      dokterPoli: data.dokterPoli,
      registrasiUlang: data.registrasiUlang,
      queueNumber: data.queueNumber
    });

    this.setState({ modalSubmit: true, loading: true },
      () => setTimeout(() => this.setState({ loading: false }), 500));
  }

  _handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  _handleChangeNoReg = (e, val) => {
    this.setState({ dataPasien: val, showFields: !this.state.showFields });
  }

  _handleChangeValReg = (e) => {
    this.setState({ dataPasien: e.target.value });
  }

  _handleCloseModal = (e) => {
    e.preventDefault();
    this.setState({ modalSubmit: false });
  }

  _handleGoToList = () => {
    window.location.reload();
  }

  _renderModalSubmit = () => {
    return (
      <Modal open={this.state.modalSubmit}>
        <Box sx={styleModal}>
          {this.state.loading ?
            <Box sx={{ width: '100%' }}>
              <h3>Menyimpan Data...</h3>
              <LinearProgress />
            </Box> :
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
              <div style={{
                position: 'absolute',
                top: 0,
                right: 0,
              }}>
                <IconButton onClick={this._handleCloseModal}>
                  <Close />
                </IconButton>
              </div>
              <CheckCircle color='success' sx={{ fontSize: 150 }} />
              <h3>Registrasi Ulang Pasien Berhasil!</h3>
              <Button
                variant="contained"
                color="success"
                onClick={this._handleGoToList}
              >
                Lihat List Pasien
              </Button>
            </div>
          }
        </Box>
      </Modal>
    );
  }

  render() {
    const { dataTable, dataPasien, showFields } = this.state;
    let newData = (dataTable || []).filter(val => val?.registrasiUlang === undefined);
    return (
      <Grid container spacing={2}>
        {this._renderModalSubmit()}
        <Grid item xs={4}>
          <Autocomplete
            className='wrappField'
            disablePortal
            id="combo-box-demo"
            onChange={this._handleChangeNoReg}
            options={newData}
            getOptionLabel={(option) => option && option.noRegistrasi}
            renderInput={(params) =>
              <TextField
                {...params}
                label="Kode Registrasi"
                onChange={this._handleChangeValReg}
              />
            }
          />
          {showFields &&
            <>
              <TextField
                disabled
                fullWidth
                className='wrappField'
                label="NIK"
                value={dataPasien?.nik}
              />
              <TextField
                disabled
                fullWidth
                className='wrappField'
                label="Nama Lengkap"
                value={dataPasien?.namaLengkap}
              />
              <TextField
                disabled
                fullWidth
                className='wrappField'
                label="Jenis Kelamin"
                value={dataPasien?.jenisKelamin}
              />
              <TextField
                disabled
                fullWidth
                className='wrappField'
                label="Tempat Lahir"
                value={dataPasien?.tempatLahir}
              />
              <TextField
                disabled
                fullWidth
                className='wrappField'
                label="Tanggal Lahir"
                value={moment(dataPasien?.tglLahir).format('DD-MM-YYYY')}
              />
            </>
          }
        </Grid>
        <Grid item xs={4}>
          {showFields &&
            <>
              <TextField
                disabled
                fullWidth
                className='wrappField'
                label="Alamat"
                multiline
                rows={4.3}
                type='area'
                value={dataPasien?.alamat}
              />
              <TextField
                disabled
                fullWidth
                className='wrappField'
                label="No Telepon"
                value={dataPasien?.noTelp}
              />
              <TextField
                disabled
                fullWidth
                className='wrappField'
                label="Jenis Asuransi"
                value={dataPasien?.asuransi}
              />
              <TextField
                disabled
                fullWidth
                className='wrappField'
                label="No Asuransi"
                value={dataPasien?.noAsuransi === '' ? '-' : dataPasien?.noAsuransi}
              />
              <TextField
                disabled
                fullWidth
                className='wrappField'
                label="No Rujukan"
                value={dataPasien?.noRujukan}
              />
            </>
          }
        </Grid>
        <Grid item xs={4}>
          {showFields &&
            <>
              <TextField
                disabled
                fullWidth
                className='wrappField'
                label="Tujuan Poliklinik"
                value={dataPasien?.poli}
              />
              <TextField
                disabled
                fullWidth
                className='wrappField'
                label="Tanggal Berkunjung"
                value={moment(dataPasien?.tglBerkunjung).format('DD/MM/YYYY')}
              />
              <TextField
                disabled
                fullWidth
                className='wrappField'
                label="Jam Berkunjung"
                value={dataPasien?.jamBerkunjung}
              />
              <TextField
                className='wrappField'
                id="outlined-select-currency"
                select
                label="Dokter Poli"
                fullWidth
                name='dokterPoli'
                onChange={this._handleChange}
              >
                {['dr Anindita', 'dr Aprilicia', 'dr Tambora', 'dr Lutfhi', 'dr Reynaldy', 'dr Rediyansyah', 'dr Roni'].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <Button
                disabled={
                  this.state.dokterPoli === '' ? true : false
                }
                fullWidth
                variant='contained'
                onClick={this._handleRegist}
              >
                Registrasi Ulang
              </Button>
            </>
          }
        </Grid>
      </Grid>
    );
  }
}

export default RegistrasiPasien;