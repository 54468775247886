import React, { Component } from 'react';
import './styles.css';
import StartFirebase from '../../configFirebase/index';
import { ref, onValue } from 'firebase/database';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Box, FormControl, IconButton, InputAdornment, Modal, OutlinedInput, Pagination, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment/moment';
import { Print, Search } from '@mui/icons-material';
import PrintForm from './printForm';
import { Document, PDFViewer, Page, View } from '@react-pdf/renderer';

const db = StartFirebase();

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const styleModalForm = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1200,
  height: '80%',
  bgcolor: 'background.paper',
  border: '1px solid grey',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
};

const viewer = {
  width: '100%',
  height: '550px',
};

const page = {
  backgroundColor: "white",
  color: "black",
  fontSize: 12
};

const section = {
  margin: 10,
  padding: 10,
};

const pageSize = 5;

class ListPasien extends Component {
  state = {
    preliminaryData: [],
    database: [],
    dataTable: [],
    search: '',
    pagination: {
      count: 0,
      from: 0,
      to: pageSize
    },
    dataPasien: [],
    showModal: false
  }

  componentDidMount() {
    const dbRef = ref(db, 'pasien');
    this.setState({ database: StartFirebase() });
    onValue(dbRef, (snapshot) => {
      let records = [];
      snapshot.forEach(childSnapshot => {
        let data = childSnapshot.val();
        records.push(data);
      });
      this.setState({
        dataTable: records,
        preliminaryData: records,
        pagination: {
          ...this.state.pagination,
          count: records.length
        }
      })
    });
  }

  _handleChangeSearch = (e) => {
    e.preventDefault();
    this.setState({ search: e.target.value }, () => {
      this._handleSearchPatient();
    });
  }

  _handleSearchPatient = () => {
    const { search } = this.state;
    let searchPatient = this.state.preliminaryData.filter((val) => {
      return val?.namaLengkap.toLowerCase().includes(search.toLowerCase());
    });
    this.setState({ dataTable: searchPatient });
  }

  _handleChangePagination = (e, page) => {
    const from = (page - 1) * pageSize;
    const to = (page - 1) * pageSize + pageSize;
    this.setState({
      pagination: {
        ...this.state.pagination,
        from, to
      }
    });
  }

  _handlePrint = (noReg) => () => {
    const { dataTable } = this.state;
    let fillData = (dataTable || []).filter(val => val?.noRegistrasi === noReg);
    this.setState({ dataPasien: fillData, showModal: true });
  }

  _handleCloseModal = () => {
    this.setState({ showModal: false });
  }

  _renderModal = () => {
    const { showModal, dataPasien } = this.state;
    return (
      <Modal open={showModal} onClose={this._handleCloseModal}>
        <Box sx={styleModalForm}>
          <PDFViewer style={viewer}>
            <Document>
              <Page size="A4" style={page}>
                <View style={section}>
                  <PrintForm data={dataPasien} />
                </View>
              </Page>
            </Document>
          </PDFViewer>
        </Box>
      </Modal>
    )
  }

  render() {
    const { dataTable, search, pagination } = this.state;
    const newData = dataTable.slice(pagination?.from, pagination?.to);
    return (
      <div>
        {this._renderModal()}
        <FormControl sx={{ marginBottom: 1, marginTop: -1 }}>
          <OutlinedInput
            endAdornment={
              <InputAdornment>
                <Search />
              </InputAdornment>
            }
            onChange={this._handleChangeSearch}
            placeholder="Cari pasien..."
            type="text"
            value={search}
            variant="standart"
            size='small'
            color="secondary"
          />
        </FormControl>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>NO</StyledTableCell>
                <StyledTableCell>NAMA PASIEN</StyledTableCell>
                <StyledTableCell>NO RM</StyledTableCell>
                <StyledTableCell>ASURANSI</StyledTableCell>
                <StyledTableCell>TUJUAN POLI</StyledTableCell>
                <StyledTableCell>TANGGAL BERKUNJUNG</StyledTableCell>
                <StyledTableCell>JAM BERKUNJUNG</StyledTableCell>
                <StyledTableCell>KODE REGISTRASI</StyledTableCell>
                <StyledTableCell align='center'>STATUS</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable.length === 0 ?
                <StyledTableRow>
                  <StyledTableCell colSpan={5}>Tidak Ada Pasien</StyledTableCell>
                </StyledTableRow> :
                (newData || []).map((val, idx) => {
                  return (
                    <StyledTableRow key={idx}>
                      <StyledTableCell component="th" scope="row">{idx + 1}</StyledTableCell>
                      <StyledTableCell>{val?.namaLengkap}</StyledTableCell>
                      <StyledTableCell>{val?.noRM}</StyledTableCell>
                      <StyledTableCell>{val?.asuransi}</StyledTableCell>
                      <StyledTableCell>{val?.poli}</StyledTableCell>
                      <StyledTableCell>{moment(val?.tglBerkunjung).format('DD/MM/YYYY')}</StyledTableCell>
                      <StyledTableCell>{val?.jamBerkunjung} WIB</StyledTableCell>
                      <StyledTableCell><b>{val?.noRegistrasi}</b></StyledTableCell>
                      <StyledTableCell
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        {val?.registrasiUlang ?
                          <>
                            <p className='wrapp-register'>Sudah Registrasi</p>
                          </> : <p className='wrapp-not-register'>Belum Registrasi</p>}
                      </StyledTableCell>
                      <StyledTableCell>
                        {val?.registrasiUlang &&
                          <IconButton onClick={this._handlePrint(val?.noRegistrasi)}><Print /></IconButton>
                        }
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          justifyContent={"flex-end"}
          alignItems={"center"}
          display={"flex"}
          sx={{
            position: 'fixed',
            bottom: '20px',
            right: '10px',
          }}
        >
          <Pagination
            color='primary'
            count={Math.ceil(pagination?.count / pageSize)}
            onChange={this._handleChangePagination}
          />
        </Box>
      </div>
    );
  }
}

export default ListPasien;