import { initializeApp } from "firebase/app";
import { getDatabase } from 'firebase/database';

function StartFirebase() {
  const firebaseConfig = {
    apiKey: "AIzaSyC-foz9_7QqxeGGTQ4CDBPlESYbnqUZxWY",
    authDomain: "rawat-jalan-1e745.firebaseapp.com",
    projectId: "rawat-jalan-1e745",
    storageBucket: "rawat-jalan-1e745.appspot.com",
    messagingSenderId: "666223890797",
    appId: "1:666223890797:web:5432ea63a6e822b8768a64"
  };

  const app = initializeApp(firebaseConfig);
  return getDatabase(app);
}

export default StartFirebase;
