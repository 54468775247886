import { Text, View, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';
import React, { Component } from 'react';

const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 30
  },
  tableRow: {
    margin: "5px",
    flexDirection: "row"
  },
  tableCol: {
    width: "150px",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableColTitikDua: {
    width: "10px",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableColRegist: {
    width: "120px",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    marginTop: 5,
    fontSize: 10,
    padding: 5,
    textAlign: 'left'
  },
  tableCellKodeRegist: {
    marginTop: 5,
    fontSize: 13,
    fontWeight: 'bold',
    backgroundColor: 'red',
    color: 'white',
    padding: 5,
  },
});

class PrintForm extends Component {
  render() {
    const { data } = this.props;
    console.log(data);
    return (
      <div>
        <div style={{ borderBottom: '1px solid black', textAlign: 'right', padding: 5 }}>
          <Text style={{ fontSize: 18 }}>Hermina Arcamanik Hospital (RSU Hermina Arcamanik)</Text>
          <Text>Jl. A.H. Nasution No.50, Antapani Wetan, Kec. Antapani</Text>
          <Text>Kota Bandung, Jawa Barat 40291</Text>
          <Text>Phone: 022-87242525. Fax: 022-7271771</Text>
        </div>
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Text>PENDAFTARAN PASIEN RAWAT JALAN</Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>KODE REGISTRASI</Text>
              </View>
              <View style={styles.tableColTitikDua}>
                <Text style={styles.tableCell}>:</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data && data[0] && data[0]?.noRegistrasi}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>NO REKAM MEDIS</Text>
              </View>
              <View style={styles.tableColTitikDua}>
                <Text style={styles.tableCell}>:</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data && data[0] && data[0]?.noRM}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>NAMA LENGKAP</Text>
              </View>
              <View style={styles.tableColTitikDua}>
                <Text style={styles.tableCell}>:</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{(data && data[0] && data[0]?.namaLengkap)?.toUpperCase()}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>JENIS KELAMIN</Text>
              </View>
              <View style={styles.tableColTitikDua}>
                <Text style={styles.tableCell}>:</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{(data && data[0] && data[0]?.jenisKelamin)?.toUpperCase()}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>TEMPAT LAHIR</Text>
              </View>
              <View style={styles.tableColTitikDua}>
                <Text style={styles.tableCell}>:</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{(data && data[0] && data[0]?.tempatLahir)?.toUpperCase()}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>TANGGAL LAHIR</Text>
              </View>
              <View style={styles.tableColTitikDua}>
                <Text style={styles.tableCell}>:</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{moment(data && data[0] && data[0]?.tglLahir).format('DD/MM/YYYY')}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>ASURANSI</Text>
              </View>
              <View style={styles.tableColTitikDua}>
                <Text style={styles.tableCell}>:</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{(data && data[0] && data[0]?.asuransi)?.toUpperCase()}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>NO ASURANSI</Text>
              </View>
              <View style={styles.tableColTitikDua}>
                <Text style={styles.tableCell}>:</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data && data[0] && data[0]?.noAsuransi}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>NO RUJUKAN</Text>
              </View>
              <View style={styles.tableColTitikDua}>
                <Text style={styles.tableCell}>:</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data && data[0] && data[0]?.noRujukan}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>TUJUAN POLIKLINIK</Text>
              </View>
              <View style={styles.tableColTitikDua}>
                <Text style={styles.tableCell}>:</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{(data && data[0] && data[0]?.poli)?.toUpperCase()}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>TANGGAL BERKUNJUNG</Text>
              </View>
              <View style={styles.tableColTitikDua}>
                <Text style={styles.tableCell}>:</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{moment(data && data[0] && data[0]?.tglBerkunjung).format('DD/MM/YYYY')}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>JAM BERKUNJUNG</Text>
              </View>
              <View style={styles.tableColTitikDua}>
                <Text style={styles.tableCell}>:</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data && data[0] && data[0]?.jamBerkunjung} WIB</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>DOKTER POLIKLINIK</Text>
              </View>
              <View style={styles.tableColTitikDua}>
                <Text style={styles.tableCell}>:</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{(data && data[0] && data[0]?.dokterPoli)?.toUpperCase()}</Text>
              </View>
            </View>
          </View>
        </div>
      </div>
    );
  }
}

export default PrintForm;