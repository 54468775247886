import React, { Component } from 'react';
import './styles.css';
import { Alert, AlertTitle, Button, IconButton, Stack } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

class Login extends Component {
  state = {
    username: '',
    password: '',
    showpass: false,
    errorLogin: false,
    successLogin: false,
  }

  _handleChangeLogin = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  _handleLogin = () => {
    const { username, password } = this.state;
    if (username === 'adedisty' && password === 'adedisty') {
      localStorage.setItem('login', true);
      this.setState({ successLogin: true }, () => {
        setTimeout(() => window.location.reload(), 3000)
      });
    } else {
      this.setState({ errorLogin: true }, () => {
        setTimeout(() => this.setState({ errorLogin: false }), 3000)
      });
    }
  }

  _handleShowPass = () => {
    this.setState({ showpass: !this.state.showpass });
  }

  _renderAlertSuccess = () => {
    return (
      <Stack sx={{ position: 'absolute', top: 10 }} spacing={2}>
        <Alert severity="success">
          <AlertTitle>Login Berhasil!</AlertTitle>
          Anda akan dialihkan ke halaman Pendaftaran Pasien Rawat Jalan
        </Alert>
      </Stack>
    );
  }

  _renderAlertError = () => {
    return (
      <Stack sx={{ position: 'absolute', top: 10 }} spacing={2}>
        <Alert severity="error">
          <AlertTitle>Login Gagal!</AlertTitle>
          Cek kembali Username dan Password yang Anda masukkan.
        </Alert>
      </Stack>
    );
  }

  render() {
    const { username, password, errorLogin, successLogin, showpass } = this.state;
    return (
      <div className='container'>
        {successLogin && this._renderAlertSuccess()}
        {errorLogin && this._renderAlertError()}
        <div className='wrapp-form'>
          <h2>SELAMAT DATANG</h2>
          <p>Silahkan Login terlebih dahulu untuk masuk ke Pendaftaran Pasien Rawat Jalan</p>
          <p><b>RSU HERMINA ARCAMANIK BANDUNG</b></p>
          <div className='wrapp-fields'>
            <p>Username</p>
            <input
              style={{ marginLeft: 3, width: '375px', marginBottom: 30, padding: 10 }}
              name='username'
              onChange={this._handleChangeLogin}
            />
            <p>Password</p>
            <div style={{ position: 'relative' }}>
              <input
                style={{ marginLeft: 3, width: '375px', padding: 10 }}
                type={showpass ? 'text' : 'password'}
                name='password'
                onChange={this._handleChangeLogin}
              />
              {showpass ?
                <IconButton
                  onClick={this._handleShowPass}
                  style={{ position: 'absolute', right: 5, top: -4, zIndex: 2 }}
                >
                  <VisibilityOff style={{ fontSize: '30px', color: 'grey' }} />
                </IconButton> :
                <IconButton onClick={this._handleShowPass} style={{ position: 'absolute', right: 5, top: -4, zIndex: 2 }}>
                  <Visibility style={{ fontSize: '30px', color: 'grey' }} />
                </IconButton>
              }
            </div>
          </div>
          <Button
            disabled={
              username === '' ||
                password === '' ? true : false
            }
            fullWidth
            color='inherit'
            variant='outlined'
            onClick={this._handleLogin}
          >
            Login
          </Button>
        </div>
      </div>
    );
  }
}

export default Login;