import { TabContext, TabPanel } from '@mui/lab';
import './App.css';
import { Box, Button, Tab, Tabs } from '@mui/material';
import React, { Component } from 'react';
import ListPasien from './pages/ListPasien';
import RegistrasiPasien from './pages/RegistrasiPasien';
import Login from './pages/Login';
import { Logout } from '@mui/icons-material';

class App extends Component {
  state = {
    valueTab: 1,
    valLogin: localStorage.getItem('login')
  }

  _handleChangeTabs = (e, val) => {
    this.setState({ valueTab: val })
  }

  _handleLogout = () => {
    localStorage.removeItem('login');
    window.location.reload();
  }

  render() {
    const { valueTab, valLogin } = this.state;
    if (valLogin) {
      return (
        <React.Fragment>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={valueTab}>
              <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={valueTab}
                  onChange={this._handleChangeTabs}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="secondary tabs example"
                >
                  <Tab value="1" label="List Pasien Rawat Jalan" />
                  <Tab value="2" label="Registrasi Ulang Pasien Rawat Jalan" />
                </Tabs>
              </Box >
              <TabPanel value='1'><ListPasien /></TabPanel>
              <TabPanel value='2'><RegistrasiPasien /></TabPanel>
            </TabContext>
          </Box>
          <Button className='wrapp-btn-logout' onClick={this._handleLogout}>
            Logout <Logout fontSize='small' style={{ marginLeft: 10 }} />
          </Button>
        </React.Fragment>
      );
    } else {
      return <Login />;
    }
  }
}

export default App;